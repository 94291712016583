import type { Placement } from '@floating-ui/dom';
import HttpClient from '@naturehouse/nh-essentials/lib/requests/HttpClient';
import HttpError from '@naturehouse/nh-essentials/lib/requests/HttpError';
import InitializationError from '@naturehouse/nh-essentials/lib/exceptions/InitializationError';
import FloatingUIElementManager, {
    iFloatingUIElement,
    PlacementOption
} from '../../../../src/utils/FloatingUIElementManager';
import './ToolTip.pcss';

const showEvents: string[] = ['mouseenter', 'focus'];
const hideEvents: string[] = ['mouseleave', 'blur'];

export type ToolTipProps = {
    id: string;
    content?: string;
    endpoint?: string;
    show?: boolean;
    placement?: Placement;
};

export type ToolTipResponseProps = {
    body: string;
};

export default class ToolTip extends HTMLElement implements iFloatingUIElement {
    #target: HTMLElement | null = null;

    get target(): HTMLElement | null {
        if (this.#target) {
            return this.#target;
        }

        if (!this.targetId) {
            throw new Error('No target element ID provided to Tooltip');
        }

        const element: HTMLElement | null = this.#getTargetElement();
        this.#target = element;
        return this.#target;
    }

    get targetId(): string | null {
        return this.getAttribute('target-id');
    }

    set targetId(value: string | null) {
        if (!value) {
            this.removeAttribute('target-id');
            this.#removeTargetEventListeners(this.#target);
            return;
        }

        this.setAttribute('target-id', value);

        const element: HTMLElement | null = this.#getTargetElement();
        if (element === null) {
            return;
        }

        this.#removeTargetEventListeners(this.#target);
        this.#setTargetEventListeners(element);

        this.#target = element;
    }

    get placement(): Placement {
        const placement: string | null = this.getAttribute('placement');
        const placementOptions: string[] = <string[]>Object.values(PlacementOption);

        if (placement === null || !placementOptions.includes(placement)) {
            return 'top' as Placement;
        }

        return placement as Placement;
    }

    set placement(value: Placement) {
        const placementOptions: string[] = <string[]>Object.values(PlacementOption);

        if (!placementOptions.includes(value)) {
            return;
        }

        this.setAttribute('placement', value);
    }

    get element(): HTMLElement {
        return this;
    }

    #arrowElement: HTMLElement | null = null;

    get arrowElement(): HTMLElement {
        if (this.#arrowElement === null) {
            this.#arrowElement = document.createElement('DIV');
            this.#arrowElement.setAttribute('slot', 'arrow');
        }

        return this.#arrowElement;
    }

    get opened(): boolean {
        return this.hasAttribute('show');
    }

    set opened(value: boolean) {
        this.toggleAttribute('show', value);

        if (value === false) {
            return;
        }

        this.#showHide();
    }

    #endpoint: string | null = this.getAttribute('endpoint');

    #contentLoaded: boolean = false;

    #hoverActive: boolean = false;

    #timeout: number | undefined;

    #floatingUIElementManager: FloatingUIElementManager<this>;

    public constructor(props?: ToolTipProps) {
        super();
        this.#floatingUIElementManager = new FloatingUIElementManager<this>(this);
        const shadow: ShadowRoot = this.attachShadow({ mode: 'open' });
        shadow.innerHTML =
            '<slot name="content">&nbsp;</slot><slot name="close"></slot><slot name="arrow"></slot>';
        this.#setShadowRootEventListeners(shadow);
        this.#initProps(props);
    }

    public connectedCallback(): void {
        this.#target = this.#getTargetElement();
        this.addEventListener('click', this.#preventClickEvent);

        if (this.#target === null) {
            throw new InitializationError();
        }

        this.#setTargetEventListeners(this.#target);
    }

    public disconnectedCallback(): void {
        this.removeEventListener('click', this.#preventClickEvent);

        if (this.#target === null) {
            return;
        }

        this.#floatingUIElementManager.cleanupFloatingUi();
        this.#removeTargetEventListeners(this.#target);
    }

    #setTargetEventListeners(target: HTMLElement): void {
        target.addEventListener('click', this.#preventClickEvent);
        target.addEventListener('mouseenter', this.#handleMouseEnter);
        target.addEventListener('mouseleave', this.#handleMouseLeave);

        for (const event of showEvents) {
            target.addEventListener(event, this.show);
        }

        for (const event of hideEvents) {
            target.addEventListener(event, this.close);
        }
    }

    #setShadowRootEventListeners(shadowRoot: ShadowRoot): void {
        const closeButton = shadowRoot.querySelector('[name="close"]');

        if (!closeButton) {
            return;
        }

        closeButton.addEventListener('click', this.#handleMouseLeave);
    }

    #removeTargetEventListeners(target: HTMLElement | null): void {
        if (target === null) {
            return;
        }

        target.removeEventListener('click', this.#preventClickEvent);
        target.removeEventListener('mouseenter', this.#handleMouseEnter);
        target.removeEventListener('mouseleave', this.#handleMouseLeave);

        for (const event of showEvents) {
            target.removeEventListener(event, this.show);
        }

        for (const event of hideEvents) {
            target.removeEventListener(event, this.close);
        }
    }

    public show = (): void => {
        clearTimeout(this.#timeout);
        this.opened = true;
    };

    public close = (): void => {
        clearTimeout(this.#timeout);
        this.#timeout = window.setTimeout((): void => {
            if (this.#hoverActive) {
                clearTimeout(this.#timeout);
                return;
            }
            this.opened = false;
        }, 350);
    };

    #initProps(props?: ToolTipProps): void {
        if (!props) {
            return;
        }

        if (props.placement && !this.hasAttribute('placement')) {
            this.placement = props.placement;
        }

        if (props.id) {
            this.targetId = props.id;
        }

        if (props.show) {
            this.show();
        }

        if (props.content) {
            this.#initContent(props.content);
            return;
        }

        this.#endpoint = props.endpoint ?? this.#endpoint;
    }

    #initContent(content: string): void {
        const element: HTMLElement = document.createElement('DIV');
        element.setAttribute('slot', 'content');
        element.innerHTML = content;
        this.innerHTML = '';
        this.append(element, this.arrowElement);
        this.#contentLoaded = true;
    }

    async #showHide(): Promise<void> {
        await this.#getContentFromEndpoint();
        this.#updatePosition();
    }

    async #updatePosition(): Promise<void> {
        await this.#floatingUIElementManager.updatePosition({
            offset: {
                mainAxis: 12,
                alignmentAxis: -4
            },
            shift: {
                padding: 8
            },
            arrow: {
                padding: 8,
                element: this.arrowElement
            }
        });
    }

    async #getContentFromEndpoint(): Promise<void> {
        if (!this.#endpoint || this.#contentLoaded) {
            return;
        }

        const response = (await HttpClient.get(this.#endpoint, { json: false })) as
            | string
            | ToolTipResponseProps;
        if (response instanceof HttpError) {
            return;
        }

        let content: string = '';

        if (typeof response === 'string') {
            content = response;
        } else {
            content = response.body;
        }

        this.#initContent(content);
    }

    readonly #preventClickEvent = (event: Event): void => {
        event.stopPropagation();
    };

    readonly #handleMouseEnter = (): void => {
        this.#hoverActive = true;
    };

    readonly #handleMouseLeave = (): void => {
        this.#hoverActive = false;
        this.close();
    };

    #getTargetElement(): HTMLElement | null {
        return (
            this.parentElement?.querySelector(`[data-tool-tip="${this.targetId}"]`) ??
            document.querySelector(`[data-tool-tip="${this.targetId}"]`)
        );
    }
}

if (!window.customElements.get('nh-tool-tip')) {
    window.customElements.define('nh-tool-tip', ToolTip);
}
